<template>
  <div class="container my-2 text-center" v-if="getOffer">


    <div class="row mt-5 container">
      <div class="bd-example w-100">

        <div id="carousel-home" class="carousel slide d-flex justify-content-center row" data-ride="carousel"
          style="height:20rem;">

          <div class="carousel-inner w-100 h-100">
            <div v-if="getOffer.images == 0" class="carousel-item pt-0 w-100 h-100 active">
              <img src="../../img/photo.svg" alt="" class="d-block mx-auto w-100 h-75" />
              <h2 class="text-center mt-1">لايوجد صور متاحة</h2>
            </div>
            <div class="carousel-item   pt-0 w-100 h-100" v-for="(slide, i) in getOffer.images"
              :class="{ active: isOne(i) }">
              <img rel="preload" class="d-block mx-auto w-100 h-100" :src="'https://images.scrapiz.com/' + slide"
                alt="part image">
            </div>
          </div>

          <div class="row">
            <ol class="carousel-indicators">
              <li v-for="(slide, i) in getOffer.images" data-target="#carousel-home" :data-slide-to="i"
                :class="{ active: isOne(i) }"></li>
            </ol>
          </div>

        </div>
      </div>


    </div>
    <div class="row row-cols-md-2 row-cols-1 justify-content-between my-3">


      <div :class="inputClasses" style=" direction: ltr">
        <span class="form-control text-center price">
          {{ getOffer.offerAmount + " ريال " }}
        </span>
        <span class="input-group-text  bw-span">قيمة القطعة</span>
      </div>

      <div :class="inputClasses" style=" direction: ltr" v-if="!getOffer.pickUp">
        <span class="form-control text-center price">
          {{ getOffer.shippingAmount + "ريال" }}
        </span>
        <span class="input-group-text  bw-span">قيمة الشحن</span>
      </div>



      <div :class="inputClasses" style=" direction: ltr" v-if="getOffer.codCharge">
        <span class="form-control text-center price">
          {{ getOffer.codCharge + " ريال " }}
        </span>
        <span class="input-group-text  bw-span">قيمة الدفع عند الاستلام</span>
      </div>








      <div :class="inputClasses" style=" direction: ltr" v-if="getOffer.pickUp">
        <span class="form-control text-center">
          {{ getOffer.shipFrom.city }}
        </span>
        <span class="input-group-text  bw-span">موقع التشليح </span>
      </div>


      <div :class="inputClasses" style=" direction: ltr">
        <span class="form-control text-center price">
          {{ getOffer.insurancePeriod > 0 ? getOffer.insurancePeriod + "يوم" : "لايوجد" }}
        </span>
        <span class="input-group-text  bw-span"> الضمان</span>
      </div>

      <div :class="inputClasses" style=" direction: ltr" v-if="!getOffer.pickUp">
        <span class="form-control text-center price">
          {{ getOffer.deliveryPeriod + "يوم" }}
        </span>
        <span class="input-group-text  bw-span"> مدة التوصيل</span>
      </div>

      <div :class="inputClasses" style=" direction: ltr">
        <span class="form-control text-center price">
          {{ getOffer.returnPeriod + "يوم" }}
        </span>
        <span class="input-group-text  bw-span"> مدة الاسترجاع</span>
      </div>

      <div :class="inputClasses" style=" direction: ltr">
        <span class="form-control text-center price">
          {{ getOffer.replacePeriod + "يوم" }}
        </span>
        <span class="input-group-text  bw-span"> مدة الاستبدال</span>
      </div>



    </div>

    <div class="container text-center border border-light rounded font-weight-bold my-3"
      style="background:#ced4da;font-size: 1.25rem;color: #000000c7;">
      ملاحظات البائع </div>



    <div class="text-center border  rounded my-3" style="height: 10rem;width: auto;word-wrap: break-word;padding: 1rem;">
      {{ getOffer.comment ? getOffer.comment.trim() : "لايوجد" }}


    </div>


  </div>
</template>


<script>
import { GET_FULL_OFFER } from '../../graphql/queries';


export default {
  data() {
    return {
      routeParam: this.$route.params,
      inputClasses: ['input-group', 'input-group-lg', 'mt-3', 'px-2'],
      getOffer: [],
    }
  },
  apollo: {
    getOffer: {
      query: GET_FULL_OFFER,
      variables() {
        return {
          RequestID: this.routeParam.requestID,
          OfferID: this.routeParam.offerID
        };
      },
      result(result) {
        this.$store.commit('glopal/isLoading', false);
      },
    },
  },
  methods: {

    isOne(index) {
      if (index == 0) {
        return true;
      }
    },

  }


}
</script>

<style scoped></style>
