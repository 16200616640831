<template>
  <div class="container my-2 " v-if="getOrder">
    <div class="row mt-5 container">
      <div class="bd-example w-100">
        <div
          id="carousel-home"
          class="carousel slide d-flex justify-content-center row"
          data-ride="carousel"
          style="height:20rem;"
        >
          <div class="carousel-inner w-100 h-100">
            <div
              class="carousel-item   pt-0 w-100 h-100"
              v-for="(slide, i) in getOrder.part.images"
              :class="{ active: isOne(i) }"
            >
              <img
                rel="preload"
                class="d-block mx-auto w-100 h-100"
                :src="'https://images.scrapiz.com/' + slide"
                alt="part image"
              />
            </div>
          </div>

          <div class="row">
            <ol class="carousel-indicators">
              <li
                v-for="(slide, i) in getOrder.part.images"
                data-target="#carousel-home"
                :data-slide-to="i"
                :class="{ active: isOne(i) }"
              ></li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5 justify-content-between">
      <div
        class="input-group input-group-lg mb-3 col-lg-5 col-sm-12"
        style="direction: ltr"
      >
        <span class="form-control text-center">
          {{ getOrder.part.manufacturer }}
        </span>
        <span class="input-group-text  bw-span">الشركة المصنعة</span>
      </div>
      <div
        class="input-group input-group-lg mb-3 col-lg-5 col-sm-12"
        style=" direction: ltr"
      >
        <span class="form-control text-center">
          {{ getOrder.part.model }}
        </span>
        <span class="input-group-text  bw-span">السيارة</span>
      </div>
    </div>

    <div class="row mt-2 justify-content-between">
      <div class="input-group input-group-lg mb-3 col-lg-5 col-sm-12"" style="
      direction: ltr">
      <span class="form-control text-center">
        {{ getOrder.part.year }}
      </span>
      <span class="input-group-text  bw-span">سنة الصنع</span>
    </div>
    <div
      class="input-group input-group-lg mb-3 col-lg-5 col-sm-12"
      style=" direction: ltr"
    >
      <span class="form-control text-center">
        {{ getOrder.part.name }}
      </span>
      <span class="input-group-text  bw-span">اسم القطعة</span>
    </div>
  </div>

  <div class="row mt-5 justify-content-between">
    <div class="input-group input-group-lg mb-3 col-12" style="direction: ltr">
      <div
        class="input-group-text   font-weight-bolder w-100 justify-content-center "
      >
        عنوان المشتري
      </div>
    </div>
  </div>

  <div class="row mt-2 justify-content-between">
    <div
      class="input-group input-group-lg mb-3 col-lg-5 col-sm-12"
      style=" direction: ltr"
    >
      <span class="form-control text-center">
        {{ getOrder.shipTo.city }}
      </span>
      <span class="input-group-text  bw-span">المدينة</span>
    </div>
    <div
      class="input-group input-group-lg mb-3 col-lg-5 col-sm-12"
      style=" direction: ltr"
    >
      <span class="form-control text-center">
        {{ getOrder.shipTo.district }}
      </span>
      <span class="input-group-text  bw-span">الحي</span>
    </div>
    
  </div>                  
    <!-- <div class="row mt-2 justify-content-between"> -->
    <!-- <div -->
    <!-- class="input-group input-group-lg mb-3 col-lg-5 col-sm-12" -->
    <!-- style=" direction: ltr" -->
    <!-- > -->
    <!-- <span class="form-control text-center"> -->
    <!-- {{ getOrder.shipTo.city }} -->
    <!-- </span> -->
    <!-- <span class="input-group-text  bw-span">المدينة</span> -->
    <!-- </div> -->
    <!-- <div -->
    <!-- class="input-group input-group-lg mb-3 col-lg-5 col-sm-12" -->
    <!-- style=" direction: ltr" -->
    <!-- > -->
    <!-- <span class="form-control text-center"> -->
    <!-- {{ getOrder.shipTo.postalCode }} -->
    <!-- </span> -->
    <!-- <span class="input-group-text  bw-span">الرمز البريدي</span> -->
    <!-- </div> -->
    <!-- </div> -->

    <!-- <div class="row mt-2 justify-content-between"> -->
    <!-- <div -->
    <!-- class="input-group input-group-lg mb-3 col-lg-5 col-sm-12" -->
    <!-- style=" direction: ltr" -->
    <!-- > -->
    <!-- <span class="form-control text-center"> -->
    <!-- {{ getOrder.shipTo.district }} -->
    <!-- </span> -->
    <!-- <span class="input-group-text  bw-span">الحي</span> -->
    <!-- </div> -->
    <!-- <div -->
    <!-- class="input-group input-group-lg mb-3 col-lg-5 col-sm-12" -->
    <!-- style=" direction: ltr" -->
    <!-- > -->
    <!-- <span class="form-control text-center"> -->
    <!-- {{ getOrder.shipTo.streetName }} -->
    <!-- </span> -->
    <!-- <span class="input-group-text  bw-span">الشارع</span> -->
    <!-- </div> -->
    <!-- </div> -->

    <!-- <div class="row mt-2 justify-content-between"> -->
    <!-- <div -->
    <!-- class="input-group input-group-lg mb-3 col-lg-5 col-sm-12" -->
    <!-- style=" direction: ltr" -->
    <!-- > -->
    <!-- <span class="form-control text-center"> -->
    <!-- {{ getOrder.shipTo.buildingNo }} -->
    <!-- </span> -->
    <!-- <span class="input-group-text  bw-span">رقم المنزل</span> -->
    <!-- </div> -->
    <!-- <div -->
    <!-- class="input-group input-group-lg mb-3 col-lg-5 col-sm-12" -->
    <!-- style=" direction: ltr" -->
    <!-- > -->
    <!-- <span class="form-control text-center"> -->
    <!-- {{ getOrder.shipTo.addNo }} -->
    <!-- </span> -->
    <!-- <span class="input-group-text  bw-span">الرقم الاضافي</span> -->
    <!-- </div> -->
    <!-- </div> -->

    <div class="row mt-5 justify-content-between">
      <div
        class="input-group input-group-lg mb-3 col-12"
        style="direction: ltr"
      >
        <div
          class="input-group-text   font-weight-bolder w-100 justify-content-center "
        >
          الاجمالي
        </div>
      </div>
    </div>
    <div class="row mt-2 justify-content-between">
      <div
        class="input-group input-group-lg mb-3 col-lg-5 col-sm-12"
        style=" direction: ltr"
      >
        <span class="form-control text-center" style="direction: rtl">
          {{ getOrder.partPrice}} {{ "ريال " }}
        </span>
        <span class="input-group-text  bw-span">قيمة القطعة</span>
      </div>
      <div
        class="input-group input-group-lg mb-3 col-lg-5 col-sm-12"
        style=" direction: ltr"
      >
        <span class="form-control text-center" style="direction: rtl">
          {{ getOrder.shippingPrice }} {{ "ريال " }}
        </span>
        <span class="input-group-text  bw-span"> قيمة الشحن</span>
      </div>
    </div>

    <div class="row mt-2 justify-content-between">
      <div
        class="input-group input-group-lg mb-3 col-lg-5 col-sm-12"
        style=" direction: ltr"
      >
        <span class="form-control text-center">
          {{ getOrder.cod ? "نعم" : "لا" }}
        </span>
        <span class="input-group-text  bw-span"> هناك دفع عند الاستلام؟</span>
      </div>
      <div
        class="input-group input-group-lg mb-3 col-lg-5 col-sm-12"
        style=" direction: ltr"
      >
        <span class="form-control text-center" style="direction: rtl">
          {{ getOrder.cod ? getOrder.codCharge : 0 }} {{ "ريال " }}
        </span>
        <span class="input-group-text  bw-span"> قيمة الدفع عند الاستلام</span>
      </div>
      <div
        class="input-group input-group-lg mb-3 col-lg-5 col-sm-12"
        style=" direction: ltr"
      >
        <span class="form-control text-center" style="direction: rtl">
          {{ getOrder.pickUp ? "نعم" : "لا" }}
        </span>
        <span class="input-group-text  bw-span"> سيتم الاستلام من المتجر؟</span>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_FUUL_ORDER } from "../../graphql/queries";

export default {
  created() {
    /* this.$store
      .dispatch("glopal/loadFullOrder", this.$route.params.orederID)
      .then((FullOrder) => {
        this.getOrder = FullOrder;
        this.getOrder.part = FullOrder.part;
      }); */
  },
  data() {
    return {
      userInfo: {
        Fname: "",
        Lname: "",
        Email: "",
      },
      showModal: false,
      stepNo: 1,
      getOrder: null,
      orederID: this.$route.params.orderID,
    };
  },
  apollo: {
    getOrder: {
      query: GET_FUUL_ORDER,
      variables() {
        return {
          OrderID: this.orederID,
        };
      },
      result(result) {
        this.$store.commit("glopal/isLoading", false);
      },
    },
  },

  methods: {
    OrderStatus(status) {
      if (status == 1) {
        return "تم انشاء الطلب";
      } else if (status == 2) {
        return "تم الشحن";
      } else if (status == 3) {
        return "تم الإستلام";
      } else {
        return "ملغى";
      }
    },

    submit(e, payload) {
      this.$router.push(
        "/CompleteOrder/" + this.getOrder.part._id + "/" + payload._id
      );
    },
    isOne(index) {
      if (index == 0) {
        return true;
      }
    },
  },
};
</script>

<style scoped></style>
